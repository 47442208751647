<script lang="ts">
	import { icons } from '$lib/config/svg-base';
	import { technologyIcons } from '$lib/config/svg-technologyIcons';
	import type { Service } from '$lib/domain/service.interface';

	export let service: Service [] = [];
</script>

{#if !!service.url?.length}
	<article class="linked">
		<a href="{service.url}">
			<div>
				<div class="service-card-icon">
					{@html icons[service.iconName] ? icons[service.iconName] : technologyIcons[service.iconName]}
				</div>

				<h3>{service.title}</h3>

				<p>
					{@html service.content}
				</p>

				<div class="spacer"></div>
			</div>
		</a>
	</article>
{:else}
	<article>
		<div>
			<div class="service-card-icon">
				{@html icons[service.iconName] ? icons[service.iconName] : technologyIcons[service.iconName]}
			</div>
			
			<h3>{service.title}</h3>
			
			<p>
				{@html service.content}
			</p>
			
			<div class="spacer"></div>
		</div>
	</article>
{/if}

<style lang="scss">
	@import '$styles/vars';

	.linked {
      	&:hover {
        	border: 4px solid #69B518;
      	}
	}

	article {
		padding: 36px 24px;
		background: rgba(255, 255, 255, 0.05);
		backdrop-filter: blur(12px);
		border: 4px solid transparent;
		border-radius: 12px;
		color: $main-white;

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
		}

		.service-card-icon {
			margin-bottom: 24px;
		}

		h3 {
			text-align: center;
			font-size: 18px;
			font-weight: 700;
			line-height: 22px;
		}

		p {
			color: $main-white;
			text-align: center;
			margin-top: 12px;
		}

		.spacer {
			height: 100%;
		}
	}
</style>
