<script lang="ts">
	import ExpertiseCard from "./ExpertiseCard.svelte";
	import { swipe } from 'svelte-gestures';
	import { onMount } from 'svelte';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import type { Service } from '$lib/domain/service.interface';

	export let data: Service[] = [];
	export let title: string;
	export let description: string | undefined = '';

	let activeIndex = 0;
	const maxCount = data.length;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	onMount(() => {
			if (!window.matchMedia('(max-width: 1400px)').matches) {
					return;
			}

			carouselHelper.resetInterval();
			carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));
	});
</script>

<div class="block-bg">
	<div class="container">
		<div class="section-title">
			<h2>{@html title}</h2>
		</div>
		
		{#if !!description}
			<p class="h5">{@html description}</p>
		{/if}
		
		<div class="services">
			{#each data as service}
				<ExpertiseCard service="{service}" />
			{/each}
		</div>
		
		<div
			class="carousel"
			use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
			on:swipe="{carouselHelper.handleSwipe}">
			{#each data as service, i}
				<div class="carousel-item" class:active="{activeIndex === i}">
					<ExpertiseCard service="{service}" />
				</div>
			{/each}
		</div>
		
		<ol class="carousel-indicators">
			{#each Array(maxCount) as _, i}
				<li
					on:click="{() => carouselHelper.select(i)}"
					on:keydown="{() => carouselHelper.select(i)}"
					class:active="{i === activeIndex}">
					<div class="carousel-bullet">•</div>
				</li>
			{/each}
		</ol>
	</div>
</div>

<style lang="scss">
	@import '$styles/vars';

	.block-bg {
		padding-top: 60px;
		padding-bottom: 60px;
		background: url(/expertise.webp) center/cover no-repeat;
	}

	.services {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 24px;
		margin-top: 48px;
		color: $main-white;
	}

	.carousel,
	.carousel-indicators {
		display: none;
	}

    .carousel-bullet {
      color: $light-green;

      &:hover {
        color: $dark-grey;
      }
    }

	h2 {
		color: $main-white;

	}

	.h5 {
		color: $main-white;
		max-width: 720px;

	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		.services {
			grid-gap: 12px;
		}
	}

	@media (max-width: $portrait-phone-size) {
		.services {
			display: none;
		}

		.carousel,
		.carousel-indicators {
			display: flex;
		}
	}
</style>
